<template>
<!--  <h2>Seconde Partie : L'Action</h2>-->
  <div class="subpart">
<!--    <subpartB01 v-if="subpart_ === 'partB01'" :key="subpart_" @nextsubpart="nextsubpart" />-->
    <subpartB02 v-if="subpart_ === 'partB02'" :key="subpart_" @nextsubpart="nextsubpart" />
    <subpartB03 v-if="subpart_ === 'partB03'" :key="subpart_" @nextsubpart="nextsubpart" />
    <subpartBR1 v-if="subpart_ === 'partBR1'" :key="subpart_" @nextsubpart="nextsubpart" />


    <div class="summary summary_partA" v-if="subpart_ === 'progress'" :key="subpart_">
      <h2>Votre progression</h2>
<!--      <router-link :class="{done: progression.partB01.done}" to="/partB/partB01">La règle du jeu</router-link>-->
<!--      <router-link :class="{done: progression.partB02.done}" to="/partB/partB03">L'analyse des entretiens par l'équipe "Expérience Patient"</router-link>-->
      <router-link  to="/about/partRB50">Seconde partie : l'Action</router-link>
      <router-link :class="{done: progression.partB02.done}" to="/partB/partB02">Le plan d'amélioration</router-link>
<!--      <router-link :class="{done: progression.partB03.done}" to="/partB/partB03">Résultats</router-link>-->

    </div>
  </div>
  <div id="veil" v-if="veiled"></div>

</template>

<script>
// import subpartB01 from '../components/subpartB01.vue';
import subpartB02 from '../components/subpartB02.vue';
import subpartB03 from '../components/subpartB03.vue';
import subpartBR1 from '../components/subpartBR1.vue';

// const subparts = [
//   'partB01', 'partB02',
//   '/about/partRB60'
// ];

export default {
  name: "partB",
  props: ['subpart'],
  data() {
    return {
      subpart_: this.subpart,
      veiled: false,
    }
  },
  methods: {
    nextsubpart() {
      this.veiled = false;
      console.log('sending nextsubpart from partB');
      this.$emit('nextsubpart', '/partB/' + this.subpart_);

      /*const idx = subparts.indexOf(this.subpart_);
      console.log('Next subpart ' + idx, this.subpart_);
      if ((idx >= 0) && (idx < subparts.length - 1)) {
        this.progression[this.subpart_].done = true;
        this.$store.dispatch('saveProgression');
        // window.location = '/partA/' + subparts[idx + 1];
        this.$router.push('/partB/' + subparts[idx + 1]);
      }*/
    },
    clear_data: function (/*evt*/) {
      // console.log('Clearing ' + localStorage.length);
      for (let i = localStorage.length; i > 0 ; i--) {
        let k = localStorage.key(i - 1);
        if (k.startsWith('expat.partB.')) {
          // console.log('Deleting ' + k);
          localStorage.removeItem(k);
        } else {
          // console.log('Not deleting ' + k);
        }
      }
      localStorage.removeItem('expat.progression');
    }
  },
  components: {
    // subpartB01,
    subpartB02,
    subpartB03,
    subpartBR1,
  },
  created() {
    this.$watch(
        () => this.$route,
        () => {
          // console.log('Parent watched route', this.subpart, this.subpart_, this.$route);
          this.subpart_ = this.$route.params.subpart;
          // this.$emit('reloadkeywords');
          // console.log('↳ children:', this.$refs);
        },
        {immediate: true}
    );
    // console.log('watching route', this.subpart, this.subpart_);
  },
  emits: ['nextsubpart'],
  /*beforeRouteUpdate(to, from) {

    console.log('beforeRouteUpdate parent ', from, to);
    // this.loadKeywords(this.subpart);
  },
  // watch: {
  //   // call method if the route changes
  //   '$route': 'reInitialize'
  // },
  /*beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.

    // const id = to.params.id
    // this.AJAXRequest(id)
    next()
  },*/
  computed: {
    progression() {
      return this.$store.state.parts;
    }
  },
  /*setup() {
    const {ref} = Vue;
    const partA01 = ref(null);

    return {partA01};
  }*/
}
</script>

<style scoped>

/*@media screen and (min-width: 1300px) {
  H2 {
    text-align: center;
  }

  .subpart {
    width: 1300px;
    margin: auto;
  }

}
*/
.summary {
  margin-left: 30px;
}

.summary_partA {}

.summary A {
  text-decoration: none;
  display: block;
  margin: 5px;
}

.summary .done {
  color: green;
}

.summary .done:before {
  content: '✓ ';
  color: green;
}
</style>