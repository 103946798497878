<template>
  <div class="subpartB02 subpart_div">
    <h2 class="white_title">L'Action</h2>
    <h3 class="white_title">Le plan d'action</h3>

    <hr />

    <div class="expert_on_the_left">Voici la liste des <span class="emphase">actions possibles</span>.
      Faîtes-les glisser dans le plan d'action dans l'<span class="emphase">ordre de priorité</span> qui vous semble le plus adapté en vous appuyant sur
      les <span class="emphase">fiches actions</span> et la <span>synthèse</span> que vous venez d'écouter.
    </div>

    <hr/>

    <div class="plan_layout">
      <div class="plan_left">

        <div class="target_title">Actions possibles</div>
        <div class="wrapper">
          <!--      <div id="action_source_div" @mouseover="hover_panel='righttrrt'" @mouseleave="hover_panel='ttt'">-->
          <div id="action_source_div">
            <draggable
                id="action_source"
                ref="action_source"
                v-model="action_source"
                group="actions"
                item-key="id"
                @change="onActionChange"
                @start="selected_action = {}; dragging = true"
                @end="dragging = false"
            >
              <template #item="{element}">
                <div class="draggable_action" @mouseover="showAction(element)"
                     :class="{'shown': selected_action === element}"
                     @click="locked = true">
                  <div class="action_name">{{ element.name }}
                    <!--                    <span class="action_move_down" @click="actionMoveDown(element)">▼</span>-->
                  </div>
                </div>
              </template>
            </draggable>

          </div>

          <p class="target_title">Plan d'action</p>
          <div id="plan_div">
            <draggable
                id="plan_target"
                ref="plan_target"
                v-model="plan_target_items"
                group="actions"
                item-key="id"
                @change="onActionChange"
            >
              <template #item="{element}">
                <div class="draggable_action" @mouseover="showAction(element)"
                     :class="{'shown': selected_action === element}"
                     @click="locked = true">
                  <div class="action_name">{{ element.name }}
                    <!--                    <span class="action_move_up" @click="actionMoveUp(element)">▲</span>-->
                  </div>
                </div>
              </template>
              <template #header>
                <p class="keyword_empty" v-if="plan_target_items.length < 1">Faites glisser ici les actions en les classant par ordre de priorité décroissante</p>
              </template>
            </draggable>
          </div>


        </div>
      </div>
      <div class="plan_right">
        <div class="target_title">Fiche action</div>

        <div id="right_flex">
          <div id="action_div">
<!--            <div class="action_label">Objectifs</div>-->
            <div class="action_goal_title">Objectifs</div>
            <div class="action_goal">
              <div class="action_goal_description" v-html="selected_action.goal"></div>
            </div>
            <!--          <DoughnutChart class="action_donut" :chartData="testData"/>-->
            <div class="action_goal_title">Caractéristiques (sur une échelle de 0 à 30)</div>
            <div class="action_figures">
              <div class="action_value action_figure_label"><br/>Plus-value<br/>créée
                <span class="action_figure">{{ selected_action.value }}</span>
              </div>
              <div class="action_feasibility action_figure_label"><br/><br/>Faisabilité
                <span class="action_figure">{{ selected_action.feasibility }}%</span>
              </div>
              <div class="action_resources action_figure_label"><br/><br/>Ressources
                <span class="action_figure">{{ selected_action.resources }}</span>
              </div>
            </div>
          </div>
          <div id="summary">
            <div class="target_title">Chiffrage du plan d'action</div>
            <div class="action_figures">
              <div class="action_value action_figure_label"><br/>Plus-value<br/>prévue
                <span class="action_figure">{{ total_value }}</span>
              </div>
              <div style="width: 110px">&nbsp;</div>
              <div class="action_resources action_figure_label"><br/><br/>Ressources
                <span class="action_figure">{{ total_resources }}</span>
              </div>

            </div>


            <!--          <p>Total ressources prévues : {{ total_resources }} 💰</p>-->
            <!--          <p>Valeur créée totale prévue : {{ total_value_pro + total_value_pat + total_value_eta }} 💎, dont :</p>-->
            <!--          <blockquote>-->
            <!--            Professionnels : {{ total_value_pro }} 💎<br/>-->
            <!--            Patients : {{ total_value_pat }} 💎<br/>-->
            <!--            Établissement : {{ total_value_eta }} 💎-->
            <!--          </blockquote>-->

          </div>
        </div>
      </div>
    </div>

    <v-tour name="tour_10" :steps="tour_steps_10" :options="tour_options" :callbacks="tour_cb_10"></v-tour>

  </div>

  <hr/>
  <!--  <button @click="nextsubpart">Suivant</button>-->


<!--  <div id="actual">-->
<!--    <p>Total ressources utilisées : {{ total_resources_cap }} 💰</p>-->
<!--    <p>Valeur totale réellement créée : {{ total_value_pro_cap + total_value_pat_cap + total_value_eta_cap }} 💎</p>-->
<!--    <blockquote>-->
<!--      Professionnels {{ total_value_pro_cap }} 💎<br/>-->
<!--      Patients {{ total_value_pat_cap }} 💎<br/>-->
<!--      Établissement {{ total_value_eta_cap }} 💎<br/>-->
<!--    </blockquote>-->
<!--  </div>-->


  <div class="bottom_buttons">
    <button class="big" @click="nextSubPart">Suivant</button>
  </div>
</template>

<script>
// import { Chart, DoughnutController, ArcElement, Tooltip } from 'chart.js';
// Chart.register(DoughnutController, ArcElement, Tooltip);

// import {DoughnutChart} from 'vue-chart-3';
// import {Chart, registerables} from "chart.js";
import {defineComponent,} from 'vue';

import draggable from 'vuedraggable';
import {mapGetters} from "vuex";

// Chart.register(...registerables);

const default_action_source = [
  {
    id: 1,
    name: 'Améliorer la signalétique',
    goal: '<ul><li>Faciliter l\'orientation des patients</li></ul>',
    value: 10,
    feasibility: 80,
    resources: 5,
    pat: 60,
    pro: 10,
    eta: 30
  },
  {
    id: 2,
    name: 'Améliorer l\'identification des professionnels',
    goal: '<ul><li>Faciliter la reconnaissance des pros par les patients</li></ul>',
    value: 14,
    feasibility: 100,
    resources: 4,
    pat: 57,
    pro: 15,
    eta: 29
  },
  {
    id: 3,
    name: 'Simplifier la consultation d\'anesthésie',
    goal: '<ul><li>Faciliter l’orientation des patients</li></ul>',
    value: 12,
    feasibility: 40,
    resources: 10,
    pat: 50,
    pro: 16,
    eta: 34
  },
  {
    id: 4,
    name: 'Revoir accueil initial à Hôpital de jour',
    goal: '<ul><li>Rendre l’accueil initial plus chaleureux</li><li>Fournir aux patients une information pertinente</li></ul>',
    value: 12,
    feasibility: 80,
    resources: 4,
    pat: 50,
    pro: 25,
    eta: 25
  },
  {
    id: 5,
    name: 'Réorganiser la prise en charge en hôpital de jour',
    goal: '<ul><li>Simplifier l’identito-vigilance</li><li>Limiter le nombre d’intervenants par patient</li><li>Homogénéiser les messages fournis aux patients</li></ul>',
    value: 12,
    feasibility: 50,
    resources: 8,
    pat: 50,
    pro: 34,
    eta: 16
  },
  {
    id: 6,
    name: 'Améliorer les conditions d’attente',
    goal: '<ul><li>Rendre l’attente plus agréable</li></ul>',
    value: 9,
    feasibility: 80,
    resources: 5,
    pat: 50,
    pro: 20,
    eta: 30
  },
  {
    id: 7,
    name: 'Améliorer accueil et prise en charge en salle d’anesthésie',
    goal: '<ul><li>Améliorer accueil et confidentialité</li><li>Améliorer la précision des explications</li></ul>',
    value: 11,
    feasibility: 60,
    resources: 5,
    pat: 64,
    pro: 18,
    eta: 18
  },
  {
    id: 8,
    name: 'Réviser les modalités de sortie',
    goal: '<ul><li>Améliorer la signalisation</li><li>Veiller au respect des conditions impératives de sortie</li><li>Aménager les conditions d’accueil de l’accompagnant</li></ul>',
    value: 18,
    feasibility: 80,
    resources: 4,
    pat: 44,
    pro: 12,
    eta: 44
  },
  {
    id: 9,
    name: 'Améliorer le suivi immédiat au domicile',
    goal: '<ul><li>Rassurer, éviter au patient d’avoir à décider\nSécuriser la prise en charge post intervention</li></ul>',
    value: 19,
    feasibility: 80,
    resources: 4,
    pat: 60,
    pro: 10,
    eta: 30
  },
  {
    id: 10,
    name: 'Communiquer le ressenti des patients au personnel',
    goal: '<ul><li>Valoriser le personnel et conforter son attitude</li></ul>',
    value: 20,
    feasibility: 100,
    resources: 4,
    pat: 25,
    pro: 40,
    eta: 35
  }
];

// const optimized_actions = [1,2,4,8,9,10];

export default defineComponent({
  name: "subpartB02",
  components: {
    draggable,
    // DoughnutChart
  },
  data() {
    return {
      locked: false,
      plan_target_items: [],
      dragging: false,
      // hover_panel: '',
      action_source: [],
      selected_action: {},
      tour_options: {
        labels: {
          buttonSkip: 'Passer les explications',
          buttonPrevious: 'Précédent',
          buttonNext: 'Suivant',
          buttonStop: 'Terminer'
        },
        highlight: true
      },
      tour_cb_10: {
        onFinish: () => this.$parent.veiled = false,
        onSkip: () => this.$parent.veiled = false,
        // onSkip: () => this.$emit('nextsubpart'),
      },
      tour_steps_10: [
        {
          target: 'H3',
          header: {
            title: 'Bienvenue !',
          },
          content: 'Nous allons vous montrer comment élaborer votre plan d’action.'
        },
        {
          target: '#action_source',
          content: 'En plaçant la flèche sur le libellé d’une action vous pouvez faire apparaître les caractéristiques de celle-ci.'
        },
        {
          target: '#plan_div',
          content: 'Pour élaborer votre plan d’action vous devez faire glisser avec votre souris les actions vers\n' +
              'le bas dans la rubrique Plan d’action.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#plan_div',
          content: 'L\'ordre de priorité est important dans le cas où le budget ne permettrait pas de tout réaliser.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#summary',
          content: 'Au fur et à mesure que vous élaborez votre plan d’action deux compteurs affichent la plus-value\n' +
              'prévisionnelle créée et les ressources employées',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#plan_div',
          content: 'Pour changer l’ordre de priorité il vous suffit de déplacer les actions avec votre souris.',
          params: {
            placement: 'top'
          }
        },
      ],
    }
  },
  computed: {
    total_resources() {
      let total_res = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        total_res += val.resources;
      });
      return total_res;
    },
    total_resources_cap() {
      let total_res = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        if (total_res + val.resources <= 25) {
          total_res += val.resources;
        }
      });
      return total_res;
    },
    total_value() {
      let total = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        total += val.value;
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_pat() {
      let total = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        total += val.value * val.pat / 100;
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_pat_cap() {
      let total = 0, total_res = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        if (total_res + val.resources <= 25) {
          total_res += val.resources;
          total += val.value * val.feasibility / 100 * val.pat / 100;
        }
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_pro() {
      let total = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        total += val.value * val.pro / 100;
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_pro_cap() {
      let total = 0, total_res = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        if (total_res + val.resources <= 25) {
          total_res += val.resources;
          total += val.value * val.feasibility / 100 * val.pro / 100;
        }
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_eta() {
      let total = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        total += val.value * val.eta / 100;
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_eta_cap() {
      let total = 0, total_res = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        if (total_res + val.resources <= 25) {
          total_res += val.resources;
          total += val.value * val.feasibility / 100 * val.eta / 100;
        }
      });
      return Math.floor(total * 10) / 10;
    },
    // action_source() {
    //   if (!this.action_source_cache) {
    //     const actionSource = this.getSubpart('partB.partB02.action_source') || default_action_source;
    //     console.log('Compute action source', actionSource);
    //     this.action_source_cache = JSON.parse(JSON.stringify(actionSource));
    //   }
    //   return this.action_source_cache;
    // },
    // plan_target_items() {
    //   const targetItems = this.getSubpart('partB.partB02.plan_target_items') || [];
    //   console.log('Compute target items', targetItems);
    //   return JSON.parse(JSON.stringify(targetItems));
    // },
    // selected_action() {
    //   return this.action_source[0];
    // },
    ...mapGetters([
      'getSubpart',
    ])
  },
  methods: {
    saveProgression: function () {
      console.log('Part2 save');
      this.$store.dispatch('saveProgression', {path: 'partB.partB02.action_source', data: this.action_source});
      this.$store.dispatch('saveProgression', {path: 'partB.partB02.plan_target_items', data: this.plan_target_items});
      this.$store.dispatch('sendProgression');
    },
    onActionChange() {
      // localStorage.setItem('expat.partB.action_source', JSON.stringify(this.action_source));
      // localStorage.setItem('expat.partB.plan_target_items', JSON.stringify(this.plan_target_items));
      this.saveProgression();
    },
    nextSubPart() {
      this.$emit('nextsubpart');
    },
    showAction: function (action) {
      // console.log(action);
      if (!this.dragging) {
        this.selected_action = action;
        // this.updatePieData();
      }
    },
    hideAction: function () {
      if (!this.locked) {
        this.selected_action = {};
      }
    },
    actionMoveDown: function (el) {
      console.log('CLICK', el, this, this.$refs);
      this.action_source = this.action_source.filter(
          (val) => {
            return val.id !== el.id
          }
      )
      this.plan_target_items.push(el);
    },
    actionMoveUp: function (el) {
      console.log('CLICK', el, this, this.$refs);
      this.plan_target_items = this.plan_target_items.filter(
          (val) => {
            return val.id !== el.id
          }
      )
      this.action_source.push(el);
    },
  },
  emits: ['nextsubpart'],
  setup() {
    /*const data = ref([0, 0, 0]);
    const my_labels = ref(['Établissement', 'Patients', 'Professionnels']);
    const testData = computed(() => ({
      labels: my_labels.value,
      datasets: [
        {
          data: data.value,
          cutout: 20,
          animation: {animateRotate: false},
          backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED'],
        },
      ],
    }));

    function updatePieData() {
      // data.value = shuffle(data.value);
      let action = this.selected_action;
      my_labels.value = [
        'Établissement : ' + (1.0 * action.eta * action.value / 100) + ' 💎 (' + action.eta + '%)',
        'Patients : ' + (1.0 * action.pat * action.value / 100) + ' 💎 (' + action.pat + '%)',
        'Professionnels : ' + (1.0 * action.pro * action.value / 100) + ' 💎 (' + action.pro + '%)',
      ];
      data.value = [this.selected_action.eta, this.selected_action.pat, this.selected_action.pro];
    }

    // console.log('legend', Chart.defaults.plugins.legend);
    // Chart.defaults.plugins.legend.align = 'start';
    // Chart.defaults.plugins.legend.labels.boxWidth = 15;

    return {testData, updatePieData};*/

    // this.selected_action = this.action_source[0];
  },
  mounted() {
    console.log('partB02 is mounted');
    this.$parent.veiled = true;
    // this.action_source = JSON.parse(localStorage.getItem('expat.partB.action_source')) || default_action_source;
    // this.plan_target_items = JSON.parse(localStorage.getItem('expat.partB.plan_target_items')) || [];
    this.action_source = JSON.parse(JSON.stringify(this.getSubpart('partB.partB02.action_source') || default_action_source));
    this.plan_target_items = JSON.parse(JSON.stringify(this.getSubpart('partB.partB02.plan_target_items') || []));
    // this.plan_target_items = this.$store.parts.partB.partB02.plan_target_items || [];
    // this.plan_target_items = [];
    this.selected_action = this.action_source[0];
    this.saveProgression();
    this.$tours['tour_10'].start();
  }
});
</script>

<style scoped>
@import "../../public/tour.css";
@import "../../public/action.css";
</style>