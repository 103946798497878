<template>
  <div class="subpartB03 subpart_div">
    <h2 class="white_title">L'Action</h2>
    <h3 class="white_title">Résultat de la mise en œuvre du plan</h3>

    <hr/>

    <div class="expert_on_the_left">Voici les <span class="emphase">actions </span> que vous avez pu réaliser, le
      <span class="emphase">budget</span> alloué par la direction, les <span class="emphase">ressources</span>
      que vous avez employées et le <span class="emphase">plan d'action</span> qui permet de créer le plus de
      <span class="emphase">plus-value</span> avec le <span class="emphase">budget alloué</span>.
    </div>

    <hr/>

    <div class="wide_title">Votre plan d'action</div>

    <div class="plan_layout">
      <div class="plan_left">

        <div class="target_title">Actions réalisées</div>
        <div class="wrapper">
          <!--      <div id="action_source_div" @mouseover="hover_panel='righttrrt'" @mouseleave="hover_panel='ttt'">-->
          <div id="action_source_div">
            <draggable
                id="action_choice"
                v-model="plan_target_items"
                group="actions"
                item-key="id"
                disabled="true"
            >
              <template #item="{element}">
                <div class="draggable_action"
                     :class="{'shown': selected_action === element}"
                     @click="locked = true">
                  <div class="action_name">{{ element.name }}
                    <!--                    <span class="action_move_down" @click="actionMoveDown(element)">▼</span>-->
                  </div>
                </div>
              </template>
            </draggable>
          </div>

        </div>
      </div>
      <div class="plan_right">

        <div id="right_flex">
          <div id="action_div">
            <div class="action_label">Budget</div>
            <div class="action_figures">
              <div class="action_value action_figure_label"><br/><br/>Alloué
                <span class="action_figure">25</span>
              </div>
              <div class="action_resources action_figure_label"><br/><br/>Consommé
                <span class="action_figure">{{ total_resources_cap }}</span>
              </div>
            </div>
          </div>
          <div id="summary">
            <div class="action_label">Plus-value obtenue</div>
            <div class="action_figures">
              <div class="action_value action_figure_label"><br/><br/>Prévue
                <span class="action_figure">{{ total_value }}</span>
              </div>
              <div class="action_resources action_figure_label"><br/><br/>Réalisée
                <span class="action_figure">{{ total_value_cap }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wide_title">Plan d'action permettant une plus-value créée maximale pour le budget alloué</div>

    <div class="plan_layout">
      <div class="plan_left">

        <div class="target_title">Actions réalisées</div>
        <div class="wrapper">
          <!--      <div id="action_source_div" @mouseover="hover_panel='righttrrt'" @mouseleave="hover_panel='ttt'">-->
          <div id="action_source_div">
            <draggable
                id="action_max_plan"
                v-model="max_plan"
                group="actions"
                item-key="id"
                disabled="true"
            >
              <template #item="{element}">
                <div class="draggable_action"
                     :class="{'shown': selected_action === element}"
                     @click="locked = true">
                  <div class="action_name">{{ element.name }}
                    <!--                    <span class="action_move_down" @click="actionMoveDown(element)">▼</span>-->
                  </div>
                </div>
              </template>
            </draggable>
          </div>

        </div>
      </div>
      <div class="plan_right">

        <div id="right_flex">
          <div id="action_div">
            <div class="action_label">Budget</div>
            <div class="action_figures">
              <div class="action_resources action_figure_label"><br/><br/>Consommé
                <span class="action_figure">25</span>
              </div>
            </div>
          </div>
          <div id="summary">
            <div class="action_label">Plus-value obtenue</div>
            <div class="action_figures">
              <div class="action_resources action_figure_label"><br/><br/>Réalisée
                <span class="action_figure">81</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr/>
  <!--  <button @click="nextsubpart">Suivant</button>-->


  <!--  <div id="actual">-->
  <!--    <p>Total ressources utilisées : {{ total_resources_cap }} 💰</p>-->
  <!--    <p>Valeur totale réellement créée : {{ total_value_pro_cap + total_value_pat_cap + total_value_eta_cap }} 💎</p>-->
  <!--    <blockquote>-->
  <!--      Professionnels {{ total_value_pro_cap }} 💎<br/>-->
  <!--      Patients {{ total_value_pat_cap }} 💎<br/>-->
  <!--      Établissement {{ total_value_eta_cap }} 💎<br/>-->
  <!--    </blockquote>-->
  <!--  </div>-->


  <div class="bottom_buttons">
    <button class="big" @click="nextSubPart">Suivant</button>
  </div>
</template>

<script>
// import { Chart, DoughnutController, ArcElement, Tooltip } from 'chart.js';
// Chart.register(DoughnutController, ArcElement, Tooltip);

// import {DoughnutChart} from 'vue-chart-3';
// import {Chart, registerables} from "chart.js";
import {defineComponent,} from 'vue';

import draggable from 'vuedraggable';
import {mapGetters} from "vuex";

// Chart.register(...registerables);


export default defineComponent({
  name: "subpartB03",
  components: {
    draggable,
    // DoughnutChart
  },
  data() {
    return {
      locked: false,
      plan_target_items: [],
      dragging: false,
      // hover_panel: '',
      selected_action: {},
      total_value: 0,
      max_plan: [
        {name: 'Communiquer l\'avis des patients au personnel'},
        {name: 'Améliorer le suivi immédiat au domicile'},
        {name: 'Réviser les modalités de sortie'},
        {name: 'Améliorer l\'identification des professionnels'},
        {name: 'Revoir l\'accueil initial à l\'hôpital de jour'},
        {name: 'Améliorer la signalétique '},
      ]
    }
  },
  computed: {
    total_resources() {
      let total_res = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        total_res += val.resources;
      });
      return total_res;
    },
    total_resources_cap() {
      let total_res = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        if (total_res + val.resources <= 25) {
          total_res += val.resources;
        }
      });
      return total_res;
    },
    /*total_value() {
      let total = 0;
      this.plan_target_items.forEach((val/*, idx* /) => {
        total += val.value;
      });
      return Math.floor(total * 10) / 10;
    },*/
    total_value_pat() {
      let total = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        total += val.value * val.pat / 100;
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_pat_cap() {
      let total = 0, total_res = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        if (total_res + val.resources <= 25) {
          total_res += val.resources;
          total += val.value * val.feasibility / 100 * val.pat / 100;
        }
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_pro() {
      let total = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        total += val.value * val.pro / 100;
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_pro_cap() {
      let total = 0, total_res = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        if (total_res + val.resources <= 25) {
          total_res += val.resources;
          total += val.value * val.feasibility / 100 * val.pro / 100;
        }
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_eta() {
      let total = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        total += val.value * val.eta / 100;
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_eta_cap() {
      let total = 0, total_res = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        if (total_res + val.resources <= 25) {
          total_res += val.resources;
          total += val.value * val.feasibility / 100 * val.eta / 100;
        }
      });
      return Math.floor(total * 10) / 10;
    },
    total_value_cap() {
      let value = parseInt('' + (this.total_value_eta_cap + this.total_value_pat_cap + this.total_value_pro_cap));
      // localStorage.setItem('expat.partB.action_result', '' + parseInt(value));
      this.$store.dispatch('saveProgression', {path: 'partB.partB02.action_result', data: parseInt(value)});
      console.log('action result is ' + value);
      return value;
    },
    ...mapGetters([
      'getSubpart',
    ])
  },
  methods: {
    nextSubPart() {
      this.$emit('nextsubpart');
    },
  },
  mounted() {
    console.log('subpartB03 mounted');
    /*this.plan_target_items*/
    // let temp_plan = JSON.parse(localStorage.getItem('expat.partB.plan_target_items')) || [];
    let temp_plan = JSON.parse(JSON.stringify(this.getSubpart('partB.partB02.plan_target_items'))) || [];
    let total = 0, total_res = 0;
    temp_plan.forEach((val/*, idx*/) => {
      if (total_res + val.resources <= 25) {
        total_res += val.resources;
        total += val.value;
        this.plan_target_items.push(val);
      }
    });
    this.total_value = Math.floor(total * 10) / 10;
  },
  emits: ['nextsubpart'],
});
</script>

<style scoped>
@import "../../public/action.css";
</style>