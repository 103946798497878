<template>
  <div class="subpart_div">
    <h2 class="white_title">L'action</h2>
    <h3 class="white_title">Taux d'efficacité du plan d'action</h3>

    <div class="expert_on_the_left">Voici le <span class="emphase">taux d'efficacité</span> de votre plan d'action.
    </div>

    <hr/>

    <div :class="['listening_report', action_rating]">
      <img :src="'/img/action_' + action_rating + '.png'"/>
      <div class="listening_score">
        {{ action_score }}<br/>%
      </div>
    </div>

    <hr/>

    <div class="expert_on_the_left">Pour terminer le jeu et enregistrer vos résultats, cliquez sur <span class="emphase">suivant</span>.
    </div>

    <hr/>


    <div class="bottom_buttons">
      <button class="big" @click="nextsubpart()">Suivant</button>
    </div>

  </div>
</template>

<script>


// import {keywords_check_step} from "@/utils";

import {mapGetters} from "vuex";

export default {
  name: "subpartBR1",
  components: {
  },
  data() {
    return {
      action_result: 0
    }
  },
  computed: {
    action_score: function () {
      // console.log('Score: ' + this.action_result + ' -> ' + this.action_result * 100 / 81);
      return parseInt('' + (this.action_result * 100 / 81));
    },
    action_rating: function () {
      let score = this.action_score;
      return score < 50 ? 'mauvais' : score < 80 ? 'moyen' : 'bon';
    },
    total_resources() {
      let total_res = 0;
      this.plan_target_items.forEach((val/*, idx*/) => {
        total_res += val.resources;
      });
      return total_res;
    },
    ...mapGetters([
      'getSubpart',
    ])
  },
  mounted() {
    // this.action_result = JSON.parse(localStorage.getItem('expat.partB.action_result')) || 0;
    this.action_result = JSON.parse(JSON.stringify(this.getSubpart('partB.partB02.action_result'))) || 0;
    console.log('Plan loaded: ', this.plan_target_items);
  },
  methods: {
    nextsubpart() {
      console.log('sending nextsubpart from subpartBR1');
      this.$emit('nextsubpart');
    },
  },
  emits: ['nextsubpart'],
}
</script>


<style>

.listening_score {
  position: absolute;
  left: 8%;
  top: 24%;
  text-align: center;
  font-size: 6vw;
  color: white;
  font-weight: 600;
  width: 13%;
  line-height: 0.9;
  /*outline: 1px solid blue;*/
}

@media screen and (min-width: 1000px) {
  .listening_score {
    font-size: 60px;
  }
}

.listening_report {
  background-size: cover;
  /*aspect-ratio: 1316 / 504;*/
  position: relative;
}
</style>